import { template as template_ba98cff24c9c4726a3919a44a61061e1 } from "@ember/template-compiler";
const FKLabel = template_ba98cff24c9c4726a3919a44a61061e1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
