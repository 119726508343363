import { template as template_3b33f257e4674eb9abcd29aedc8f73d1 } from "@ember/template-compiler";
const FKText = template_3b33f257e4674eb9abcd29aedc8f73d1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
