import { template as template_a78ce960632c433f85d62286b0178bb8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a78ce960632c433f85d62286b0178bb8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
